import Chat, { Bubble, useMessages } from '@chatui/core';
import '@chatui/core/dist/index.css';
import './chatui-theme.css'
import { Avatar } from '@chatui/core';
import { Flex, FlexItem } from '@chatui/core';
import { Image } from '@chatui/core';
import { Icon } from '@chatui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons'
import axios from "axios";

	
const App = () => {
  const { messages, appendMsg, setTyping } = useMessages([]);
  const robo_img = "robot.png";
  const user_img = "man_speak.png";

  const chatbotApi = process.env.REACT_APP_CHATBOT_API;

  const sdk = require("microsoft-cognitiveservices-speech-sdk");
//Find your key and resource region under the 'Keys and Endpoint' tab in your Speech resource in Azure Portal
//Remember to delete the brackets <> when pasting your key and region!
//const speechConfig = sdk.SpeechConfig.fromSubscription("d69387c26f074a13870775dce253c4f5","southcentralus");

   const headers = {
   'Access-Control-Allow-Origin':'*',	
  'Content-Type': 'application/json'
   };

function synthesizeToSpeaker(messageVoice) {
    const speechConfig = sdk.SpeechConfig.fromSubscription(process.env.REACT_APP_AZURE_SPEECH_API_KEY,process.env.REACT_APP_AZURE_SPEECH_API_LOCATION);

    speechConfig.speechSynthesisLanguage = "zh-HK"; // e.g. "de-DE"
    // The voice setting will overwrite language setting.
    // The voice setting will not overwrite the voice element in input SSML.
    speechConfig.speechSynthesisVoiceName = "zh-HK-HiuMaanNeural";
	 
    const audioConfig = sdk.AudioConfig.fromDefaultSpeakerOutput();

    //const synthesizer = new SpeechSynthesizer(speechConfig, audioConfig);
    const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
	synthesizer.speakTextAsync(
       messageVoice,
        result => {
            if (result) {
                synthesizer.close();
                return result.audioData;
            }
        },
        error => {
            console.log(error);
            synthesizer.close();
        });
}

function recordSound(){
	  var subscriptionKey = process.env.REACT_APP_AZURE_SPEECH_API_KEY;
      var serviceRegion = process.env.REACT_APP_AZURE_SPEECH_API_LOCATION;

        var speechConfig = sdk.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);

        speechConfig.speechRecognitionLanguage = "zh-HK";
        var audioConfig  = sdk.AudioConfig.fromDefaultMicrophoneInput();
        var recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);

		var recordSound = '';
        recognizer.recognizeOnceAsync(
          function (result) {
			  recordSound = result.privText;
			appendMsg({
			  type: 'text',
			  content: { text: result.privText ,avater:user_img, isRobot:false},
			  position: 'right',
			});

            recognizer.close();
            recognizer = undefined;
			    const chatData = {
						"content": recordSound,
						"content_type": "text/plain",
						"expect_type": "text/plain",
						"session_info": {
							"channel": "speech3.0"
						},
						"output_language": "cantonese"
						}; 
			    var responseData = {};
				
			axios.post(chatbotApi, chatData ,{	
					headers: headers
				  } ).then(res => {
					responseData = res.data
					if (responseData.status == 'success') {
					  //const user = responseData.user
					  //...
					 
					 var isFirstRun = true;
					if(isFirstRun){
							   synthesizeToSpeaker(responseData.content[0].text);
							   isFirstRun = false;
					}
					 
					  setTimeout(() => {
						appendMsg({
						  type: 'text',
						  content: { text: responseData.content[0].text ,avater:robo_img, isRobot:true},
						});
					  }, 500);
					 
					    if (responseData.content[0].text == "請等等，依家聯絡緊住戶..."){
							 setTimeout(() => {
							appendMsg({
						  type: 'image',
						  content: { image: "connectuser.png" ,avater:robo_img, isRobot:true},
						});
						  }, 500);	
						}else if (responseData.content[0].text == "請講出你想預約嘅日期同時間"){
							  setTimeout(() => {
									appendMsg({
								  type: 'image',
								  content: { image: "bookroom.png" ,avater:robo_img, isRobot:true},
								});
								  }, 500);	
				  
							setTimeout(() => {
								appendMsg({
							  type: 'image',
							  content: { image: "timeslot.png" ,avater:robo_img, isRobot:true},
							});
							  }, 500);	
							
						}
						
						if (responseData.content.Count > 1){
							if(responseData.content[1].image.url != null)
							setTimeout(() => {
								appendMsg({
								  type: 'image',
								  content: { image: responseData.content[1].image.url ,avater:robo_img, isRobot:true},
								});
							  }, 500);
						}	  
						if (responseData.content.Count > 2){  
							if(responseData.content[2].video.url != null)
							setTimeout(() => {
								appendMsg({
								  type: 'video',
								  content: { video: responseData.content[1].video.url ,avater:robo_img, isRobot:true},
								});
							  }, 500);
						}		  

					} else {
					  alert('Something went wrong~')
					}
				 

				}); 
          },
          function (err) {
            appendMsg({
			  type: 'text',
			  content: { text: err ,avater:robo_img, isRobot:false},
			});

            window.console.log(err);

            recognizer.close();
            recognizer = undefined;
          });

}


   const fetchLongRequest = async () => {
        
		    const chatData = {
				"content": 'hihi',
				"content_type": "text/plain",
				"expect_type": "text/plain",
				"session_info": {
					"channel": "speech3.0"
				},
				"output_language": "cantonese"
				};
	
        const waitTime = 50000;
        setTimeout(() => console.log("Request taking a long time"), waitTime);
        try {
            const result = await axios({
				 method: 'post',
                url: "http://localhost:5000/api/chat",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
				data:chatData,
            });
            console.log("SUCCESS!", JSON.stringify(result.data, null, 2));
        } catch(error) {
            console.log("FAIL!", error.message);
        }
    };


  function handleSend(type, val) {
    if (type === 'text' && val.trim()) {
      appendMsg({
        type: 'text',
        content: { text: val , avater:user_img, isRobot:false},
	position: 'right',
      });


    setTyping(true);



    const chatData = {
    "content": val,
    "content_type": "text/plain",
    "expect_type": "text/plain",
    "session_info": {
        "channel": "speech3.0"
    },
    "output_language": "cantonese"
    };
    var responseData = {};
	
		


	      // setTimeout(() => {
        // appendMsg({
          // type: 'text',
		  // content: { text: "Bala bala..." ,avater:robo_img, isRobot:true},
        // });
      // }, 1000);
	  
	  
//axios.post('https://demo2.accobot.com/api/accobot/chat?user_id=user_07852', chatData ,{

//axios.post('http://localhost:5000/api/chat', chatData ,{

axios.post(chatbotApi, chatData ,{	
    headers: headers
  } ).then(res => {
    responseData = res.data
    if (responseData.status == 'success') {
      //const user = responseData.user
      //...
     
	 var isFirstRun = true;
    if(isFirstRun){
			   synthesizeToSpeaker(responseData.content[0].text);
			   isFirstRun = false;
    }
	 
      setTimeout(() => {
        appendMsg({
          type: 'text',
		  content: { text: responseData.content[0].text ,avater:robo_img, isRobot:true},
        });
      }, 500);
		
	 
	         if (responseData.content[0].text == "請等等，依家聯絡緊住戶..."){
					 setTimeout(() => {
					appendMsg({
				  type: 'image',
				  content: { image: "connectuser.png" ,avater:robo_img, isRobot:true},
				});
				  }, 500);	
		     }else if (responseData.content[0].text == "請講出你想預約嘅日期同時間"){
					 setTimeout(() => {
					appendMsg({
				  type: 'image',
				  content: { image: "bookroom.png" ,avater:robo_img, isRobot:true},
				});
				  }, 500);	
				  
				setTimeout(() => {
					appendMsg({
				  type: 'image',
				  content: { image: "timeslot.png" ,avater:robo_img, isRobot:true},
				});
				  }, 500);	
					
			  }
		
		if (responseData.content.Count > 1){
			if(responseData.content[1].image.url != null)
			setTimeout(() => {
				appendMsg({
				  type: 'image',
				  content: { image: responseData.content[1].image.url ,avater:robo_img, isRobot:true},
				});
			  }, 500);
		}	  
		if (responseData.content.Count > 2){  
			if(responseData.content[2].video.url != null)
			setTimeout(() => {
				appendMsg({
				  type: 'video',
				  content: { video: responseData.content[1].video.url ,avater:robo_img, isRobot:true},
				});
			  }, 500);
		}		  

    } else {
      alert('Something went wrong~')
    }
 

 });
    }
  }

  function renderMessageContent(msg) {
    const { type, content } = msg;
	 switch(type){
		
		case "text":
		if (content.isRobot){
			return <Flex>
			  <Avatar src={content.avater} size="lg"  shape="square" />
			<FlexItem style={{ marginLeft: '1em' }}>
			<Bubble content={content.text}/>
			</FlexItem>
		  </Flex>;
		}
		else{
			return <Flex>		 
			<FlexItem style={{ marginLeft: '1em' }}>
			 <Bubble content={content.text}/>
			</FlexItem>
			 <Avatar src={content.avater} size="lg"  shape="square" />
		  </Flex>;
			
		}
		break;
		case "image":			
		   return  <Flex>
			  <Avatar src={content.avater} size="lg"  shape="square" />
			<FlexItem style={{ marginLeft: '1em' }}>
			 <Image src={content.image} fluid/>
			</FlexItem>
		  </Flex>;
		  break;
		case "video":
			return <Flex style={{ marginRight: '1em' }}>
				<FlexItem style={{ marginRight: '1em' }}>
				<iframe width="560" height="315" src={content.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</FlexItem>
			<Avatar src={content.avater} size="lg" shape="square" />
			  </Flex>;
			  break;
	    default:
			return null;
			break;
	 }
  }

  return (
  <div className="container">
     <img className="robotpic" src="bigrobot.png"/>
  	<button onClick={recordSound} className="recordButton">
      <FontAwesomeIcon icon={faMicrophone} />
        </button>
    <Chat locale="en-US"
	  navbar={{ title: '智能助理' ,logo:'complogo.png'}}
	  inputType='text|voice'
	  recorder={{ canRecord: true }}
      messages={messages}
      renderMessageContent={renderMessageContent}
      onSend={handleSend}
    /></div>
  );
};

export default App;